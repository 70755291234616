import consumer from "./consumer"

const userauthorizedchannel = consumer.subscriptions.create("UserAuthorizedChannel", {
  connected() {
    console.log("Connected to the user channel!");
  },

  disconnected() {

  },

  received(data) {
    if(document.querySelector('body').getAttribute('data-current-user-id') === data.user.id.toString()){
      window.location.reload();
    }
  }
});
